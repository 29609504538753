import request, { MarkcoinRequest } from '@/plugins/request'

// 获取用户分享信息接口
export const getUserShareList: MarkcoinRequest = params => {
  return request({
    path: 'v1/vpn/user/getShareList',
    method: 'GET',
    params,
  })
}

// 获取设备列表接口
export const getDevicesList: MarkcoinRequest = params => {
  return request({
    path: '/v1/vpn/user/getUserDevice',
    method: 'GET',
    params,
  })
}

// 删除设备
export const deleteDevices: MarkcoinRequest = data => {
  return request({
    path: 'v1/vpn/user/removeDevice',
    method: 'POST',
    data,
  })
}

// 获取充值记录接口
export const getRechargeList: MarkcoinRequest = params => {
  return request({
    path: 'v1/vpn/getRechargeList',
    method: 'GET',
    params,
  })
}

// 获取套餐列表接口
export const getPackageList: MarkcoinRequest = () => {
  return request({
    path: 'v1/vpn/getSubscribePackageList',
    method: 'GET',
  })
}

// 获取订购套餐
export const OrderPackage: MarkcoinRequest = data => {
  return request({
    path: 'v1/vpn/subscribe',
    method: 'POST',
    data,
  })
}

// 获取充值方式列表
export const getRechargeTypeList: MarkcoinRequest = () => {
  return request({
    path: 'v1/vpn/getRechargeType',
    method: 'GET',
  })
}

// 获取充值信息地址/币种
export const getRechargeInfo: MarkcoinRequest = params => {
  return request({
    // path: 'v1/vpn/getRechargeConfig',
    path: '/v1/vpn/getRechargeConfig',
    method: 'GET',
    params,
  })
}

// 获取下载列表接口
export const getDownload: MarkcoinRequest = params => {
  return request({
    path: 'v1/vpn-home/appinfo/getList',
    method: 'GET',
    params,
  })
}

// 获取服务条款 隐私策略
export const getPrivacyPolicy: MarkcoinRequest = params => {
  return request({
    path: 'v1/vpn-helpCenter/help/content',
    method: 'GET',
    params,
  })
}
